<template>
  <div class="col-12">
    <BaseTextInput
      :value="form.walletNumber"
      :placeholder="$t('forms.walletNumber')"
      input-id="walletNumber"
      class="m-t-18"
      type="text"
      maxlength="11"
      :label="$t('forms.walletNumber')"
      :has-radius="true"
      :has-error="errors.has('walletNumber')"
      :message="errors.get('walletNumber')"
      @reset="errors.clear()"
      @submit="validateData"
      @isNumber="isNumber"
      @update-value="getLoginData"
      @input="updateWalletNumber()"
    />
  </div>
</template>

<script>
export default {
  name: 'WalletNumber',

  props: {
    errors: {
      type: Object
    },

    elemntsForm: {
      type: Object
    }
  },
  data () {
    return {
      form: this.elemntsForm
    }
  },

  methods: {
    isNumber (event) {
      if (!/\d/.test(event.key) && event.key !== '.') return event.preventDefault()
    },

    updateWalletNumber () {
      this.$emit('walletNumberInput', this.form)
    },

    validateData () {}
  }
}
</script>
