export const lookups = {
  months: [
    {
      id: '1',
      name: '01',
      value: '1'
    },

    {
      id: '2',
      name: '02',
      value: '2'
    },
    {
      id: '3',
      name: '03',
      value: '3'
    },
    {
      id: '4',
      name: '04',
      value: '4'
    },
    {
      id: '5',
      name: '05',
      value: '5'
    },
    {
      id: '6',
      name: '06',
      value: '6'
    },
    {
      id: '7',
      name: '07',
      value: '7'
    },
    {
      id: '8',
      name: '08',
      value: '8'
    },
    {
      id: '9',
      name: '09',
      value: '9'
    },
    {
      id: '10',
      name: '10',
      value: '10'
    },
    {
      id: '11',
      name: '11',
      value: '11'
    },
    {
      id: '12',
      name: '12',
      value: '12'
    }
  ],

  years: [
    { id: '22', value: '2022', name: '2022' },
    { id: '23', value: '2023', name: '2023' },
    { id: '24', value: '2024', name: '2024' },
    { id: '25', value: '2025', name: '2025' },
    { id: '26', value: '2026', name: '2026' },
    { id: '27', value: '2027', name: '2027' },
    { id: '28', value: '2028', name: '2028' }
  ]
}
