<template>
  <section class="thanks-content">
    <div class="container">
      <figure class="thanks-image">
        <img
          src="../../../src/assets/images/payment/order_confirmed.svg"
          alt="Thank you"
        >
      </figure>

      <h2
        class="m-t-20 bold-font"
        v-html="payment_success_message"
      />

      <div class="fawry-code">
        <div class="d-flex align-items-center justify-content-center">
          <span v-if="payment_id == 4">{{ $t('home.codeFawry') }}</span>
          <span v-else-if="payment_id == 47">{{ $t('home.codeAman') }}</span>
          <span v-else>{{ $t('home.orderId') }}</span>
          <span
            class="fawry-number"
            v-html="fawry_code"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'PaymentSuccess',
  data () {
    return {
      payment_success_message: localStorage.getItem('PaymentSuccessMessage'),
      fawry_code: localStorage.getItem('FawryCode'),
      payment_id: localStorage.getItem('PaymentId')
    }
  }
}
</script>

<style lang="scss" scoped>
.thanks-content {
  background-color: #f6f6f6;
  text-align: center;
  padding: 100px;
}

.fawry-code {
  width: 200px;
  background-color: white;
  margin: 20px auto;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #979299;
  font-weight: bold;
}

.fawry-number {
  color: $main-color;
}
</style>
