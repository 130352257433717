<template>
  <BaseLayout>
    <PaymentSuccess />
  </BaseLayout>
</template>

<script>
import BaseLayout from '../layouts/BaseLayout'
import PaymentSuccess from '@/components/payment-methods/payment-success.vue'

export default {
  name: 'ThankYou',
  components: {
    BaseLayout,
    PaymentSuccess
  }
}
</script>
