<template>
  <section class="premuim-inputs">
    <!-- card Number -->
    <div class="row">
      <div class="col-12 card-number">
        <BaseTextInput
          :value="form.cardNumber"
          input-id="cardNumber"
          class="m-t-18"
          type="text"
          :label="$t('forms.cardNumber')"
          :has-error="errors.has('cardNumber')"
          :message="errors.get('cardNumber')"
          @reset="errors.clear()"
          @submit="validateData"
          @update-value="getLoginData"
          @input="updatecartData()"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-6">
        <base-dropdown
          input-id="months"
          :options="lookups.months"
          :value="form.month"
          take-full-width
          :has-shadow="false"
          has-border
          :has-radius="false"
          :label="$t('forms.months')"
          class="m-t-18"
          :has-error="errors.has('month')"
          :message="errors.get('month')"
          @reset="errors.clear()"
          @update-seletced="updateSeletcedMonth"
          @input="updatecartData()"
        />
      </div>

      <div class="col-12 col-md-6">
        <base-dropdown
          input-id="years"
          :options="lookups.years"
          :value="form.year"
          take-full-width
          :has-shadow="false"
          has-border
          :has-radius="false"
          :label="$t('forms.years')"
          class="m-t-18"
          :has-error="errors.has('year')"
          :message="errors.get('year')"
          @reset="errors.clear()"
          @update-seletced="updateSeletcedYear"
          @input="updatecartData()"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { lookups } from "./data.js";
export default {
  name: "PremuimInputs",
  data() {
    return {
      form: this.elemntsForm,
      lookups
    };
  },

  props: {
    errors: {
      type: Object
    },
    elemntsForm:{
      type: Object
    }
  },

  methods: {
    updateSeletcedMonth(value) {
      this.form.month = value.value;
    },

    updateSeletcedYear(value) {
      this.form.year = value.value;
    },

    updatecartData() {
      this.$emit("cartDataInputs", this.form);
    },

    validateData() {}
  }
};
</script>

<style scoped>
@import "../payment.scss";
</style>