<template>
  <section class="payment-page p-y-40">
    <!---Loader-->
    <template v-if="isLoading">
      <Loader />
    </template>

    <!-- Payment Methods -->
    <template v-else>
      <div class="container">
        <div class="row">
          <div class="col-xl-8 col-lg-8 col-md-8 col-12">
            <form @submit.prevent="completePayment">
              <div
                class="payment-methods p-y-28 p-x-22 radius-38 w-100 white-color-bg"
              >
                <h3 class="bold-font fs-20 black-color-text m-b-18">
                  {{ $t('forms.choosePaymentMethod') }}
                </h3>

                <div
                  v-for="item in paymentWayes"
                  :key="item.id"
                  class="payment-wayes"
                >
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <div class="banckCard">
                      <label
                        :for="item.payment_id"
                        class="d-flex justify-content-start align-items-center"
                      >
                        <input
                          :id="item.payment_id"
                          v-model="selectedValue"
                          name="PaymentCard"
                          :value="item.payment_id"
                          type="radio"
                          hidden
                          @change="getPaymentMethodId(item.payment_id)"
                        >
                        <span class="radio m-r-8" />
                        <span class="light-gray-color-text medium-font">{{
                          item.payment_name
                        }}</span>
                      </label>
                    </div>

                    <img
                      :src="item.payment_image"
                      alt="payment-image"
                    >
                  </div>

                  <!-- Bank Cards Payment Inputs -->
                  <div v-if="selectedValue == 35 && item.payment_id == 35">
                    <CartInputs
                      :errors="errors"
                      :elemntsForm="form"
                      @cartDataInputs="cartDataInputs"
                    />
                  </div>

                  <!-- Vodafon Cash Inputs -->
                  <div v-if="selectedValue == 48 && item.payment_id == 48">
                    <WalletNumber
                      :errors="errors"
                      :elemntsForm="form"
                      @walletNumberInput="walletNumberInput"
                    />
                  </div>

                  <!-- premuim Payment Inputs -->
                  <div
                    v-if="selectedValue == 43 && item.payment_id == 43"
                    class="bank-card-detials"
                  >
                    <PremuimInputs
                      :errors="errors"
                      :elemntsForm="form"
                      @cartDataInputs="cartDataInputs"
                    />
                  </div>

                  <!-- Ewallet Inputs -->
                  <div v-if="selectedValue == 41 && item.payment_id == 41">
                    <WalletNumber
                      :errors="errors"
                      :elemntsForm="form"
                      @walletNumberInput="walletNumberInput"
                    />
                  </div>
                </div>

                <!-- Continue -->
                <div class="col-xl-3 col-lg-3 col-md-3 col-12">
                  <base-button
                    :text="$t('forms.continuePayment')"
                    bg-color-class="main-color-bg"
                    is-medium
                    class="m-t-20 w-100"
                  />
                </div>
              </div>
            </form>
          </div>

          <!--Card Details -->
          <!-- <div class="col-xl-4 col-lg-4 col-md-4 col-12">
            <div class="card-details p-y-28 p-x-22 radius-14 w-100">
              <h3 class="bold-font fs-20 black-color-text m-b-18">
                {{ $t('account.totalCart') }}
              </h3>

              <div
                v-for="item in cartInfo"
                :key="item.key"
              >
                <p
                  class="d-flex justify-content-between align-items-center my-3"
                >
                  <span
                    class="medium-font light-gray-color-text fs-14"
                    v-html="item.key"
                  />
                  <span class="medium-font light-gray-color-text fs-14">{{
                    item.value
                  }}</span>
                </p>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </template>
  </section>
</template>

<script>
import BaseDateInput from '@/components/partials/base-date-input/Index.vue'
import WalletNumber from './payment-inputs/wallet-number.vue'
import CartInputs from './payment-inputs/cart-inputs.vue'
import PremuimInputs from './payment-inputs/premuim-inputs.vue'
import GetPaymentData from '../../services/cart'
import CompletePayment from '../../services/cart'
import PayWithWalletNumber from '../../services/cart'
import Loader from './loader.vue'
import { mapGetters } from 'vuex'
import Form from '@/services/Form'
import Errors from '@/services/Errors'
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
  name: 'Payment',
  components: {
    BaseDateInput,
    WalletNumber,
    CartInputs,
    PremuimInputs,
    Loader
  },
  data () {
    return {
      selectedValue: null,
      isLoading: true,
      payment_id: '',
      paymentWayes: [],
      form: new Form({
        walletNumber: '',
        cardNumber: '',
        cardHolder: '',
        year: '',
        month: '',
        cvv: ''
      }),
      payload: {},
      order_id: '',
      errors: new Errors()
    }
  },

  computed: {
    cartInfo: {
      get () {
        return this.$store.state.cart.cartInfo
      },
      set (value) {
        this.$store.commit('cart/Get_User_Cart_Info', value)
      }
    },
    ...mapGetters('Globals', ['userInfo', 'userPhone'])
  },

  mounted () {
    this.cartInfo
    this.paymentData()
  },

  methods: {
    paymentData () {
      const payload = {
        token: this.getUserToken
      }

      this.isLoading = true

      GetPaymentData.getPaymentData(payload)
        .then((res) => {
          this.paymentWayes = res.data.data.payment_methods
          this.isLoading = false
        })
        .catch(() => {
          this.$router.push({ name: 'Home' })
        })
    },

    walletNumberInput (walletNumber) {
      this.form = walletNumber
    },

    cartDataInputs (form) {
      this.form = form
    },

    toggle (inputId) {
      this.form[inputId] = !this.form[inputId]
    },

    getCartQuantity () {
      this.$store.dispatch('cart/getGeneralData')
    },

    getPaymentMethodId (id) {
      this.payment_id = id
    },

    validateData () {
      ;['cardNumber', 'cardHolder', 'month', 'year', 'cvv'].forEach((el) => {
        if (this.form[el] === '') {
          this.errors.recordField({
            [el]: this.$t('forms.validValue')
          })
        }
      })
    },

    payWithWallet () {
      const payload = {
        order_id: this.order_id,
        mobile: localStorage.getItem('walletNumber')
      }

      PayWithWalletNumber.payWithWalletNumber(payload)
        .then((res) => {
          toast.success(res.data.data.popup_message)
        })
        .catch((err) => {})
    },

    completePayment () {
      this.validateData()
      this.validatePhoneNumber()

      this.payload = {
        user_id: this.userInfo.users_id,
        confirm_phone: this.userPhone,
        payment_method: this.payment_id,
        app_version: '',
        store: '',
        device_token: this.getUserIp,
        fawry_type: 'fawry'
      }

      if (this.payment_id == 43) {
        this.payload = {
          ...this.payload,
          ...{
            mpgs_card_number: this.form.cardNumber,
            mpgs_month: this.form.month,
            mpgs_year: this.form.year
          }
        }
      } else if (this.payment_id == 35) {
        this.payload = {
          ...this.payload,
          ...{
            mpgs_card_number: this.form.cardNumber,
            mpgs_card_holder: this.form.cardHolder,
            mpgs_month: this.form.month,
            mpgs_year: this.form.year
          }
        }
      } else if (this.payment_id == 41 || this.payment_id == 48) {
        this.payload = {
          ...this.payload,
          ...{
            mobile: this.form.walletNumber
          }
        }
      } else {
        this.payload = this.payload
      }

      localStorage.setItem('walletNumber', this.form.walletNumber)

      CompletePayment.completePayment(this.payload)
        .then((res) => {
          localStorage.setItem('PaymentSuccessMessage', res.data.data.message)
          localStorage.setItem('FawryCode', res.data.data.fawry_code)
          localStorage.setItem('PaymentId', this.payment_id)
          this.order_id = res.data.data.order_id
          if (this.payment_id == 55) {
            const route = res.data.data.enrollment_url
            window.location.href = route
          } else if (this.payment_id == 41) {
            this.payWithWallet()
          } else if (this.payment_id == 40) {
            const route = res.data.data.url
            window.location.href = route
          } else if (this.payment_id == 50) {
            const route = res.data.data.url
            window.location.href = route
          } else if (this.payment_id) {
            const route = res.data.data.url
            window.location.href = route
          }
          this.$router.push({ name: 'ThankYou' })
          this.getCartQuantity()
        })
        .catch((err) => {
          toast.error(err.response.data.data.message)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import './payment';
</style>
