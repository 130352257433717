<template>
  <BaseLayout>
    <Payment />
  </BaseLayout>
</template>

<script>
import BaseLayout from '../../layouts/BaseLayout'
import Payment from '../../components/payment-methods/index.vue'
export default {
  name: 'PaymentMethods',
  components: { BaseLayout, Payment }
}
</script>
