<template>
  <div class="container">
    <div class="row">
      <!-- Payment Methods Loader -->
      <div class="col-xl-8 col-lg-8 col-md-8 col-12">
        <div
          class="payment-methods p-y-28 p-x-22 radius-38 w-100 white-color-bg"
        >
          <!-- <VueSkeletonLoader
            v-show="offerLoading"
            class="bold-font fs-20 black-color-text m-b-18"
            type="rect"
            :width="70"
            :height="30"
            animation="wave"
          /> -->

          <div
            v-for="index in 4"
            :key="index"
            class="col-xl-8 col-lg-8 col-md-8 col-12 my-4"
          >
            <!-- <VueSkeletonLoader
              v-show="offerLoading"
              class="payment-loader raduis-20"
              type="rect"
              :height="40"
              animation="wave"
            /> -->
          </div>
        </div>
      </div>

      <!--Card Details -->
      <div class="col-xl-4 col-lg-4 col-md-4 col-12">
        <div class="card-details p-y-28 p-x-22 radius-14 w-100">
          <h3 class="bold-font fs-20 black-color-text m-b-18">
            <!-- <VueSkeletonLoader
              v-show="offerLoading"
              class="payment-methods raduis-20"
              type="rect"
              :width="70"
              :height="30"
              animation="wave"
            /> -->
          </h3>

          <!-- <VueSkeletonLoader
            v-show="offerLoading"
            class="payment-loader raduis-20"
            type="rect"
            :height="40"
            animation="wave"
          /> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import VueSkeletonLoader from 'skeleton-loader-vue'

export default {
  name: 'Loader',
  // components: { VueSkeletonLoader },
  data () {
    return {
      offerLoading: false
    }
  },
  mounted () {
    this.offerLoading = true
  }
}
</script>

<style lang="scss" scoped>
@import './payment';
.payment-loader {
  width: 100% !important;
}
</style>
